




















































import { Component, Prop, Vue } from 'vue-property-decorator';
import Ride from '@/models/Ride';
import { PAYMENT_TYPE } from '@/models/Payment';

@Component
export default class TaxiTableComponent extends Vue {

    public PAYMENT_TYPE = PAYMENT_TYPE;

    @Prop({ default: () => [] })
    public rides!: Ride[];

    @Prop({ default: '' })
    public searchValue!: string;

    @Prop({ default: false })
    public isLoading!: boolean;

    @Prop({ default: 2 })
    public elevation!: number;

    public tableHeaders = [{
        text: this.$t('RIDE.TABLE.DEPARTURE'),
        value: 'offer.request.departure',
        sortable: true
    }, {
        text: this.$t('RIDE.TABLE.DESTINATION'),
        value: 'offer.request.destination',
        sortable: true
    }, {
        text: this.$t('RIDE.TABLE.STARTTIME'),
        value: 'start',
        sortable: false
    }, {
        text: this.$t('RIDE.TABLE.ENDTIME'),
        value: 'end',
        sortable: false
    }, {
        text: this.$t('RIDE.TABLE.PRICE'),
        value: 'offer.price',
        sortable: false
    }, {
        text: this.$t('RIDE.TABLE.PHONE'),
        value: 'customer.phone',
        sortable: false
    }, {
        text: this.$t('RIDE.TABLE.DRIVER_NAME'),
        value: 'driverName',
        sortable: false
    }, {
        text: this.$t('RIDE.TABLE.TAXI_NO'),
        value: 'driverTaxi.taxi.taxiNo',
        sortable: true
    }, {
        text: this.$t('RIDE.TABLE.DRIVER_NO'),
        value: 'driverTaxi.driver.phone',
        sortable: false
    }, {
        text: this.$t('RIDE.TABLE.STATUS'),
        value: 'offer.status',
        sortable: true
    }];

    public rowSelected(ride: Ride) {
        console.log(ride);
    }
}

