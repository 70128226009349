var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ride-table"},[_c('v-card',{staticClass:"box-standard-shadow table-container py-1",attrs:{"elevation":_vm.elevation}},[_c('v-data-table',{staticClass:"row-pointer",attrs:{"items":_vm.rides,"loading":_vm.isLoading,"headers":_vm.tableHeaders,"search":_vm.searchValue,"items-per-page":10},on:{"click:row":_vm.rowSelected},scopedSlots:_vm._u([{key:"item.driverName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.driverTaxi.driver.firstName)+" "+_vm._s(item.driverTaxi.driver.lastName)+" ")])]}},{key:"item.offer.price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(item.offer.price))]),(item.payment)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.payment.type === _vm.PAYMENT_TYPE.CASH)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("fas fa-money-bill ")]):_vm._e(),(item.payment.type === _vm.PAYMENT_TYPE.PAYTODAY)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("fas fa-mobile ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.payment.type))])])]:_vm._e()],2)]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [(item.start)?_c('span',[_vm._v(" "+_vm._s(_vm._f("toDateTime")(item.start))+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [(item.end)?_c('span',[_vm._v(" "+_vm._s(_vm._f("toDateTime")(item.end))+" ")]):_c('span',[_vm._v("-")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }